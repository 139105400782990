import request from '@/api/request'

export const getWithdrawal = (params) => {
  return request({
    url: 'config/getWithdrawal',
    params
  })
}

export const updateWithdrawal = (params) => {
  return request({
    url: 'config/updateWithdrawal',
    method: 'POST',
    params
  })
}
